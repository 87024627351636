export default class UsersPrivilegeDatabaseBackUps {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.databaseBackupView = false;
    this.databaseBackupAdd = false;
    this.databaseBackupArchive = false;
    this.databaseBackupRestore = false;
    this.databaseBackupDelete = false;
  }
  fillData(data) {
    if (data) {
      this.databaseBackupView = data.databaseBackupView;
      this.databaseBackupAdd = data.databaseBackupAdd;
      this.databaseBackupArchive = data.databaseBackupArchive;
      this.databaseBackupRestore = data.databaseBackupRestore;
      this.databaseBackupDelete = data.databaseBackupDelete;
    } else {
      this.setInitialValue();
    }
  }
}
